import styles from './Callbacks.module.css'
import { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';


const Callback = (props) => {
    const [id, setId] = useState(props.id);
    const [event, setEvent] = useState('');
    const [url, setUrl] = useState('');
    const [selectError, setSelectError] = useState(false);
    const [urlError, setUrlError] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const handleChange = (e) => {

        if (e.target.value !== '') {
            setSelectError(false);
        }
        setEvent(e.target.value);

    }

  
    const save = async (e) => {
        e.preventDefault();
        const error = await errHandling();
        const callback = { tagId: id, event: event, url: url };

        if (error || isSaving) {
            return
        } else {
            setIsSaving(true);

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/add/callback`, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                    body: JSON.stringify(callback)
                });

                const data = await response.json();
                props.onClose();

            } catch (err) {
                console.log(err);
            }

        }

    }

    const errHandling = async () => {

        let error = false
        if (event === '') {
            setSelectError(true);
            error = true;
        }
        if (url === '') {
            setUrlError(true)
            error = true;
        }
        return error;
    }


    const backToLayOut = () => {
        props.onClose()
    }


    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <Card sx={{ width: 500, height: 400, borderRadius: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                    <CardHeader
                        action={
                            <IconButton sx={{ position: 'relative', left: '200%' }} onClick={backToLayOut}>
                                <CloseIcon />
                            </IconButton>
                        }
                        title="Add Callback"
                        sx={{ color: '#289FD2' }}
                    />
                    <CardContent sx={{ width: "inherit" }} >

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Callback</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={event}
                                label="Callback"
                                onChange={handleChange}
                                error={selectError}

                            >
                                <MenuItem value='request'>request</MenuItem>
                                <MenuItem value='start'>start</MenuItem>
                                <MenuItem value='complete'>complete</MenuItem>
                            </Select>
                            <br></br>
                            <TextField error={urlError} type='text' onChange={(e)=>{setUrl(e.target.value); e.target.value !== "" ? setUrlError(false) : setUrlError(true)}} id="outlined-basic" label="url" variant="outlined" />
                        </FormControl>

                    </CardContent>
                    <CardActions sx={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                        <button className={styles.submitButton} onClick={save}>save</button>
                    </CardActions>
                </Card >
            </Backdrop >
        </div >
    )

}

export default Callback;